.bg {
    background-image: url('../../Assets/bg.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.j-holder {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10% 0 10% 0;

    font-family: 'Bronova';
    font-size: calc(1 * (1vw + 1vh - 1vmin));
    font-size: 1vmax;
    color: var(--default-primary-color);
}
.j-holder>div {
    padding-right: 40%;
}
.j-title {
    padding-bottom: 15%;
}
.j-holder>div>div>span {
    background-color: var(--default-secondary-color);
    padding: 3.5%;
}
.j-holder>div>div>ul {
    list-style: none;
}
.j-holder>div>div>ul>li {
    padding: 2% 0 2% 0;
}
.j-holder>div>div>ul>li>a {
    text-decoration: none;
    color: inherit;
}
.j-holder>div>div>ul>li>a:hover {
    color: var(--default-secondary-color);
}

@media only screen and (max-width: 800px) {
    .j-holder {
        font-size: calc(2 * (1vw + 1vh - 1vmin));
        font-size: 2vmax;
    }
    .j-holder>div {
        padding-right: 0;
    }
    .j-holder>div>div>span {
        background-color: var(--default-secondary-color);
        padding: 3.5%;
    }
    .j-holder>div>div>ul {
        list-style: none;
    }
    .j-holder>div>div>ul>li {
        padding-top: 4%;
    }
    .j-holder>div>div>ul>li>a {
        text-decoration: none;
        color: inherit;
    }
    .j-holder>div>div>ul>li>a:hover {
        color: var(--default-secondary-color);
    }
}